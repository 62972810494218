<template>
	<div class="newheader">
		<!-- <div class="newheader_search" v-if="isappear">
			<div style="width:327px !important;text-align: left;">
				<img class="newheader_search_logo" v-show="website.logo1"  :src="website.logo1" @click="topage('/')" />
			</div>
			<div class="newheader_search_con">
				<div class="newheader_search_con_div">
					<div class="newheader_search_con_div_input">
						<img src="@/assets/header_search.png" />
						<input type="text" v-model="searchText" autocomplete="off" placeholder="请输入您想要搜索的内容" @keyup.enter.prevent="searchEnterFun($event)" />
					</div>
					<el-button type="primary" @click="getSearch()">搜索</el-button>
				</div>
			</div>
			<div class="newheader_search_right">
				<div class="xuexizhongxinclass" @click="xuexizhongxin()">学习中心</div>
				<div class="newheader_search_right_app" @click="toapp()">
					<p>下载APP</p>
					<img src="@/assets/header_phone.png" />
				</div>
				<p v-if="!islogin">
					

					<span @click="login_register()">登录</span>
					<span>｜</span>
					<span @click="login_register()">注册</span>
				</p>
				<div v-if="islogin" class="user_div">
					<img
						class="userimg"
						:src="userInfo.headPic"
						v-if="JSON.stringify(userInfo) != '{}' && userInfo != null && userInfo != undefined && userInfo.headPic != '' && userInfo.headPic != null && userInfo.headPic != undefined"
					/>
					<img class="down" src="@/assets/down.png" />   
					<div class="user_list">
						<p @click="topage('/myself/myCenter')">个人中心</p>
						<p @click="topage('/myself/myClass')">我的课程</p>
						<p @click="topage('/myself/myLive')">我的直播</p>
						<p @click="topage('/myself/myDaYi')">课程答疑</p>
						<p @click="topage('/myself/myAnswer')">我的问答</p>
						<p @click="topage('/myself/myOrder')">我的订单</p>
						<p @click="topage('/myself/editUserInfo')">个人资料</p>
						<p @click="topage('/myself/myInformation')">站内消息</p>
						<p @click="signOut()">退出登录</p>
						
						<div class="user_list_triangle"></div>
					</div>
				</div>
			</div>
		</div> -->
		<div class="newheader_nav">
			<div class="newheader_nav_div headerNav">
				<div style="width:250px !important;text-align: left;">
					<img class="newheader_search_logo" v-show="website.logo1"  :src="website.logo1" @click="topage('/')" />
				</div>
				<!-- <div v-show="isHome" @click="opensort" class="divs" :class="isSort ? 'background_ccc' : 'background_ddd'">
					<p>全部栏目</p>
				</div> -->
				<!-- <p v-show="!isHome" :class="[path == '/' ? 'background_ccc' : '', '']" @click="topage('/')" style="min-width: 6em;">首页</p> -->
				<div style="width:calc(100% - 250px);display:flex;justify-content:space-between;align-items:center">
					<div style="display: flex;">
						<p :class="[path == '/' ? 'background_ccc' : '', '']" @click="topage('/')">首页</p>
						<p :class="path == '/course' || path == '/coursedetail' ? 'background_ccc' : ''" @click="topage('/course')">选课中心</p>
						<p :class="path == '/liveList' || '' ? 'background_ccc' : ''" @click="topage('/liveList')">直播课堂</p>
						<p :class="path == '/tiku' ? 'background_ccc' : ''" @click="topage('/tiku')">在线题库</p>
						
						
						<!-- <p :class="path == '/ask' ? 'background_ccc' : ''" @click="topage('/ask')">在线答疑</p>
						<p :class="path == '/datum' ? 'background_ccc' : ''" @click="topage('/datum')">资料下载</p>
						<p :class="path == '/information' ? 'background_ccc' : ''" @click="topage('/information')">会计资讯</p> -->
						
						<!-- <p @click="tokuaijiwang()">中国会计网</p> -->
					</div>
					<div class="leftNavNewBox">
						<div class="xuexizhongxinclass" @click="xuexizhongxin()">学习中心</div>
						<div class="xiazaiBox" @click="toapp()">
							<span style="margin-right:10px;">下载APP</span>
							<img src="@/assets/header_phone.png" />
						</div>
						<div v-if="!islogin">
							<span @click="login_register()">登录</span>
							<span>｜</span>
							<span @click="login_register()">注册</span>
						</div>
						<div v-if="islogin" class="user_div ">
							<img
								class="userimg"
								:src="userInfo.headPic"
								v-if="JSON.stringify(userInfo) != '{}' && userInfo != null && userInfo != undefined && userInfo.headPic != '' && userInfo.headPic != null && userInfo.headPic != undefined"
							/>
							<img class="down" src="@/assets/down.png" />   
							<div class="user_list">
								<p @click="topage('/myself/myCenter')">个人中心</p>
								<p @click="topage('/myself/myClass')">我的课程</p>
								<p @click="topage('/myself/myLive')">我的直播</p>
								<p @click="topage('/myself/myDaYi')">课程答疑</p>
								<!-- <p @click="topage('/myself/myAnswer')">我的问答</p> -->
								<p @click="topage('/myself/myOrder')">我的订单</p>
								<p @click="topage('/myself/editUserInfo')">个人资料</p>
								<!-- <p @click="topage('/myself/myInformation')">站内消息</p> -->
								<p @click="signOut()">退出登录</p>
								<!-- <p @click="topage('/myself/myNote')">课程笔记</p> -->
								<!-- <p @click="topage('/myself/myIntegral')">积分</p> -->
								<!-- <p @click="topage('/myself/myCoupon')">优惠券</p> -->
								<!-- <p @click="topage('/myself/myClassExchange')">课程兑换</p> -->
								<div class="user_list_triangle"></div>
							</div>
						</div>

					</div>
					

				</div>
				
				
			</div>
		</div>
		<!-- <div v-if="isSort" :class="hoverindex == -1 ? 'newheader_positions' : 'newheader_position'" :style="hoverindex == -1 ? [arr] : ''">
			
			<div class="newheader_position_left">
				<div v-for="(item,index) in firstTypesList" :key="index">
					
					<div class="newheader_position_left_title" @click="tocourse(item)">
						<p>{{ item.name }}</p>
						
					</div>
					
				</div>
			</div>
		</div> -->
		<el-dialog :visible.sync="dialogVisible" width="480px" :before-close="handleClose">
			<div class="popup_login" v-if="isShowAgreement == false">
				<div class="popup_login_box" v-on:click.stop v-if="isType == '1' || isType == '2' || isType == '3'">
					<p class="popup_login_box_title">
						登录
						<button type="button" aria-label="Close" class="el-dialog__headerbtn" @click="dialogVisible = !dialogVisible">
							<i class="el-dialog__close el-icon el-icon-close"></i>
						</button>
					</p>
					<el-tabs @tab-click="handleClick" v-model="activeName" class="popup_login_box_tabs">
						<el-tab-pane label="微信登录" name="1">
							<div class="popup_login_box_tabs_one">
								<p class="popup_login_box_tabs_one_text">
									<img src="@/assets/home/WX_icon.png" alt />
									<span>请使用微信扫一扫登录</span>
								</p>
								<!-- <div class="block" v-if="checked==false" style="width:170px;height:170px;">
									<el-image>
										<div slot="error" class="image-slot" >
											请阅读并同意<br/>《用户服务协议》<br/>《隐私政策》
										</div>
									</el-image>
								</div>-->
								<p class="popup_login_box_tabs_one_codeImg" v-loading="!qrCodeUrl">
									<img :src="qrCodeUrl" alt v-if="qrCodeUrl" />
								</p>
							</div>
						</el-tab-pane>
						<el-tab-pane label="账号登录" name="2">
							<div class="popup_login_box_tabs_two">
								<div class="popup_login_box_tabs_two_form">
									<p class="popup_login_box_tabs_two_item">
										<!-- <img src="../../assets/login/phone.png" alt="" class="tel_icon"> -->
										<el-input prefix-icon="el-icon-mobile-phone" placeholder="请输入手机号" v-model="account" maxlength="11"></el-input>
									</p>
									<p class="popup_login_box_tabs_two_item">
										<!-- <img src="../../assets/login/phone.png" alt="" class="tel_icon"> -->
										<el-input prefix-icon="el-icon-key" placeholder="请输入密码" v-model="password" type="password" maxlength="32"></el-input>
										<!-- <el-input placeholder="请输入内容" prefix-icon="el-icon-unlock" v-model="password"></el-input>
										<img src="@/assets/login/block.png" alt="" @click="cut(1)" v-if="isShowPassword==true">
										<img src="@/assets/login/none.png" alt="" @click="cut(2)" v-if="isShowPassword==false">-->
									</p>
									<p class="popup_login_box_tabs_two_other">
										<!-- <el-checkbox v-model="isRemember">记住我</el-checkbox> -->
										<span style="cursor:pointer;flex:auto;text-align:right;" @click="wangjimima()">忘记密码?</span>
									</p>
									<el-button type="primary" class="btn_login" @click="login(1)" v-if="checked == true">登录</el-button>
									<el-button type="primary" class="btn_login" @click="onTips" v-if="checked == false">登录</el-button>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="验证码登录" name="3">
							<div class="popup_login_box_tabs_three">
								<div class="popup_login_box_tabs_three_form">
									<p class="popup_login_box_tabs_three_item">
										<!-- <img src="../../assets/login/phone.png" alt="" class="tel_icon"> -->
										<el-input prefix-icon="el-icon-mobile-phone" placeholder="请输入手机号" v-model="mobile" maxlength="11"></el-input>
									</p>
									<p class="popup_login_box_tabs_three_item">
										<el-input placeholder="请输入验证码" maxlength="4" :disabled="countdown != '获取验证码' ? false : true" prefix-icon="el-icon-unlock" v-model="verificationCode" class="item_input"></el-input>
										<el-button type="primary" @click="getCode()" class="item_button" :disabled="countdown != '获取验证码' ? true : false">{{ countdown }}</el-button>
									</p>
									<el-button type="primary" class="btn_login" @click="login(2)" v-if="checked == true">登录</el-button>
									<el-button type="primary" class="btn_login" @click="onTips" v-if="checked == false">登录</el-button>
								</div>
							</div>
						</el-tab-pane>
					</el-tabs>
					<div class="agreement">
						<div class="agreement_left">
							<el-checkbox v-model="checked" @change="checkedChange"></el-checkbox>
							<span style="margin-left:10px;">已阅读并同意</span>
							<span style="color: #5F7CFD" @click="goAgreement()">《用户服务协议》</span> 和
							<span style="color: #5F7CFD" @click="goPolicy()">《隐私政策》</span>
						</div>
						<span class="free" style="color: #5F7CFD" @click="changeIsType('4')">免费注册</span>
					</div>
				</div>
				<div class="popup_login_box" v-on:click.stop v-if="isType == '4'">
					<div class="register_box">
						<div class="register_box_header">
							<img src="@/assets/login/register_header.png" alt class="register_box_header_banner" />
							<!-- <img src="@/assets/login/error.png" alt="" class="register_box_header_error"> -->
							<button type="button" aria-label="Close" class="el-dialog__headerbtn" @click="dialogVisible = !dialogVisible">
								<i class="el-dialog__close el-icon el-icon-close" style="color:#fff"></i>
							</button>
						</div>
						<div class="register_box_main">
							<div class="register_box_main_left">
								<img src="@/assets/login/imgpublice.png" alt />
							</div>
							<div class="register_box_main_right">
								<p>
									<img src="@/assets/login/wx_icon.png" alt />
									<span>请使用微信扫码注册</span>
								</p>
								<p>
									<img :src="qrCodeUrl" alt />
								</p>
							</div>
						</div>
						<div class="register_box_footer">
							<span @click="changeIsType('1')">已注册去登录</span>
							<img src="@/assets/login/go.png" alt @click="changeIsType('1')" />
						</div>
					</div>
				</div>
			</div>

			<div class="pinless" v-if="isShowAgreement == true && isWangJiMiMa == false">
				<div class="pinless_box">
					<div class="pinless_box_header">验证绑定手机号</div>
					<div class="pinless_box_form">
						<p class="pinless_box_item">
							<img src="../../assets/login/phone.png" alt class="tel_icon" />
							<el-input placeholder="请输入手机号" v-model="mobile" maxlength="11"></el-input>
						</p>
						<p class="pinless_box_item">
							<el-input placeholder="请输入验证码" :disabled="countdown != '获取验证码' ? false : true" prefix-icon="el-icon-unlock" v-model="verificationCode" class="item_input"></el-input>
							<el-button type="primary" @click="getCode4()" class="item_button" :disabled="countdown != '获取验证码' ? true : false">{{ countdown }}</el-button>
						</p>
						<el-button type="primary" class="btn_login" @click="login(3)" v-if="checked == true">绑定手机号</el-button>
						<el-button type="primary" class="btn_login" :disabled="true" v-if="checked == false">绑定手机号</el-button>
					</div>
				</div>
				<p class="agreement">
					<el-checkbox v-model="checked" @change="checkedChange">已阅读并同意</el-checkbox>
					<span style="color: #5F7CFD" @click="goAgreement()">《用户服务协议》</span> 和
					<span style="color: #5F7CFD;" @click="goPolicy()">《隐私政策》</span>
					<!-- <span class="free" @click="changeIsType('4')">免费注册</span> -->
				</p>
			</div>

			<div class="pinless" v-if="isShowAgreement == true && isWangJiMiMa == true">
				<div class="pinless_box">
					<div class="pinless_box_header">忘记密码</div>
					<div class="pinless_box_form">
						<p class="pinless_box_item">
							<img src="../../assets/login/phone.png" alt class="tel_icon" />
							<el-input placeholder="请输入手机号" v-model="wj_mobile" maxlength="11"></el-input>
						</p>
						<p class="pinless_box_item">
							<el-input placeholder="请输入验证码" maxlength="4" :disabled="countdown != '获取验证码' ? false : true" prefix-icon="el-icon-unlock" v-model="wj_verificationCode" class="item_input"></el-input>
							<el-button type="primary" @click="wj_getCode()" class="item_button" :disabled="countdown != '获取验证码' ? true : false">{{ countdown }}</el-button>
						</p>
						<p class="popup_login_box_tabs_two_item" style="margin-top:25px;">
							<el-input placeholder="请输入密码" v-model="wj_password" autocomplete="new-password" maxlength="32"></el-input>
						</p>
						<p class="popup_login_box_tabs_two_item" style="margin-top:25px;">
							<el-input placeholder="请再次输入密码" v-model="wj_newpassword" autocomplete="new-password" maxlength="32"></el-input>
						</p>
						<el-button type="primary" class="btn_login" @click="chongzhimima()" style="margin-top:30px;">重置密码</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>  
</template>

<script>
import { mobile_password, mobile_send, get_qrcode, qrcode_status, smscode_login, mine_index, bind_mobild, wangzhanpeizhi, saomazhijiedenglu, chongzhimima } from "@/api/login";
import { first_types } from "@/api/myself";
export default {
	name: 'newheader',
	props: {
		searchText: {
			type: String, //搜索字段
			default: '',
		},
		isappear: {
			type: Boolean, //头部展示
			default: true,
		},
		isSort: {
			type: Boolean, //选项展示
			default: false,
		},
		isUserInfo: {
			type: Boolean, //选项展示
			default: false,
		}
	},
	data() {
		return {
			// isSort: false,
			path: '/',
			firstTypesList: [],
			// searchText: '',
			islogin: false, //是否登录
			userInfo: {},
			hoverindex: -1,
			screenList: 0,
			screenWidth: 0,
			arr: {},
			dialogVisible: false,
			isType: '1',
			activeName: '1',
			checked: true,// 是否阅读
			mobile: '',//手机号
			account: '',//账号
			password: '',//密码
			isShowPassword: true,//密码显示
			isRemember: false,//记住我
			verificationCode: '',//验证码
			countdown: '获取验证码',
			downtime: 60,
			qrCodeUrl: '',//二维码
			ticket: '',// 二维码状态所需参数
			isShowAgreement: false,
			openId: '',
			website: {},
			isWangJiMiMa: false,
			wj_mobile: '',
			wj_password: '',
			wj_newpassword: '',
			wj_verificationCode: '',
			isHome: true
		}
	},
	watch: {
		$route(to, from) {
			
			this.path = this.$store.state.userInfo.path;
			// if(to.path == '/'){
			var u_obj = localStorage.getItem('setuserInfo');
			//var u_obj =JSON.parse(localStorage.getItem('setuserInfo'));
			// console.log(u_obj);

			if (u_obj != '{}' && u_obj != null && u_obj != 'null') {
				// console.log('=============')
				//11-23 本来为false，现在改为了true，不知道为什么开始是false，改成true后有效
				this.islogin = true
			} else {
				this.islogin = false
			}
			// console.log("route:islogin:" + this.islogin);
			// }
			this.isHome = this.path == '/';
		},
		// 
		hoverindex(value) {
			this.screenWidth = document.body.clientWidth, // 屏幕宽
				this.screenList = Number((this.screenWidth - 1200) / 2)
			if (value == -1) {
				var obj = {
					left: this.screenList + 'px'
				}
				this.arr = obj
			}
		},
		isType(value) {
			// console.log('isType', value)
			if (value == 4) {
				this.getQrcode()
			}
		},

		isUserInfo(value) {
			// console.log('isUserInfo', value)
			if (value == true) {
				this.dialogVisible = true
				this.getQrcode();
			}
		},
		// 
		// checked(value){
		// 	if(value=='1' && this.checked==true && this.dialogVisible==true){
		// 		// this.getQrcode()
		// 	}else if(this.dialogVisible==true && this.checked==false && value=='1'){
		// 		// this.$message.warning('请阅读并同意《用户服务协议》和《隐私政策》')
		// 	}
		// },
		// 
		// dialogVisible(value){
		// 	if(value==true){
		// 		this.getQrcode()
		// 	}
		// },
	},
	mounted() {
		this.screenWidth = document.body.clientWidth, // 屏幕宽
			this.screenList = Number((this.screenWidth - 1200) / 2)
		this.path = this.$store.state.userInfo.path;
		this.isHome = this.path == '/';
		console.log('=========',this.path);
		// this.userInfo = this.$store.state.userInfo
		var setuserInfoObj = localStorage.getItem('setuserInfo');
		// console.log(setuserInfoObj);
		// console.log(setuserInfoObj == setuserInfoObj);
		// console.log(setuserInfoObj == null);
		// console.log(setuserInfoObj == 'null');
		if (setuserInfoObj == null || setuserInfoObj == 'null') {
			// console.log("jinru");
			localStorage.removeItem('token')
			this.islogin = false
		} else {
			// console.log("jinru2");
			this.islogin = true
			this.userInfo = JSON.parse(localStorage.getItem('setuserInfo'))
		}

		// if(u_obj=='{}' || u_obj ==null || u_obj =='null' || u_obj2==null || u_obj2==undefined){
		// 	console.log("jinru");
		// 	localStorage.removeItem('token')
		// 	this.islogin=false
		// }else{
		// 	console.log("jinru2");
		// 	this.islogin=true
		// 	this.userInfo = JSON.parse(localStorage.getItem('setuserInfo'))
		// }
		console.log("islogin:" + this.islogin);
		this.hoverindexs('-1')
		// this.mine_index()
		this.getCourseFirstTypes()
		this.getUserInfo()
		this.wangzhanpeizhi();

	},
	methods: {
		xuexizhongxin() {
			// @click="topage('/myself/myClass')" 
			//判断是否登录了
			var u_obj = localStorage.getItem('setuserInfo');
			if (u_obj != '{}' && u_obj != null && u_obj != 'null') {
				this.$router.push({
					path: '/myself/myClass',
					query: {}
				});
			} else {
				this.login_register();
			}

		},
		searchEnterFun(e) {
			var keyCode = window.event ? e.keyCode : e.which;
			if (keyCode == 13) {
				this.getSearch()
			}
		},
		wangjimima() {
			this.isWangJiMiMa = true;
			this.isShowAgreement = true;
		},
		chongzhimima() {
			if (this.wj_password != this.wj_newpassword) {
				this.$message.error("两次输入的密码不一致");
				return false;
			}
			var obj = {
				mobile: this.wj_mobile,
				newPassword: this.wj_password,
				smsCode: this.wj_verificationCode
			}
			chongzhimima(obj).then(res => {
				// console.log("重置密码");
				this.$message.success("重置密码成功");
				this.isWangJiMiMa = !this.isWangJiMiMa;
				this.isShowAgreement = false;
			}).catch(error => {
				console.log(error);
			})
		},
		login_register() {
			this.dialogVisible = true;
			this.getQrcode();
		},
		wangzhanpeizhi() {
			wangzhanpeizhi().then(res => {
				// console.log("网站配置");
				// console.log(res);
				if (res.data.code == 0) {
					this.website = res.data.data;
				}
			}).catch(error => {
				console.log(error);
			})
		},
		tokuaijiwang() {
			var url = 'http://www.canet.com.cn/';
			window.open(url, '_blank');
		},
		// 关闭弹窗
		handleClose() {
      // this.isUserInfo=false
			this.isShowAgreement = false;//!this.isShowAgreement;
			this.isWangJiMiMa = false;//!this.isWangJiMiMa;
			this.$emit('setTodos', !this.isUserInfo)
			this.dialogVisible = false
		},
		// 获取用户信息
		getUserInfo() {
			mine_index().then(res => {
				if (res.data.code == 0) {
					// this.$store.commit("setuserInfo", { userInfo: res.data.data });
					localStorage.setItem('setuserInfo', JSON.stringify(res.data.data))
					this.userInfo = res.data.data;
				} else {
					this.$message.error(res.data.msg);
					this.$router.go(-1);
				}
			}).catch(error => {
				console.log(error);
			})
		},
		checkedChange(value) {
			this.checked = value
			// if(value==true){
			// 	this.getQrcode()
			// }
		},
		// 跳转用户协议和隐私政策
		goAgreement() {
			// console.log("点击了用户协议");
			// if(this.checked==false){
			// 	let routeData = this.$router.resolve({
			// 		path: '/html', query: {} 
			// 	}); 
			// 	window.open(routeData.href, '_blank');
			// }
			let routeData = this.$router.resolve({
				path: '/html', query: { name: '服务协议' }
			});
			window.open(routeData.href, '_blank');
		},
		//隐私政策
		goPolicy() {
			// console.log("点击了隐私政策");
			let routeData = this.$router.resolve({
				path: '/html', query: { name: '隐私政策' }
			});
			window.open(routeData.href, '_blank');
		},
		// 课程所有一级分类
		getCourseFirstTypes() {
			first_types().then(res => {
				if (res.data.code == 0) {
					let firstTypesList = res.data.data;
					var arr = [];
					for (var i = 0; i < firstTypesList.length; i++) {
						if (i < 8) {
							arr.push(firstTypesList[i]);
						}
					}

					this.firstTypesList = arr;//firstTypesList;
				}
				else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 获取屏幕大小
		hoverindexs(value) {
			this.screenWidth = document.body.clientWidth, // 屏幕宽
				this.screenList = Number((this.screenWidth - 1200) / 2)
			if (value == -1) {
				var obj = {
					left: this.screenList + 'px'
				}
				this.arr = obj
			}
		},

		// 跳转页面
		topage(page) {
			this.$router.push({
				path: page,
				query: {}
			});
		},
		// 打开分类
		opensort() {
			this.isSort = !this.isSort
		},
		// 去app
		toapp() {
			this.$router.push({
				path: '/appdetail',
			});
		},

		// 搜索
		getSearch() {
			// console.log("getSearch method..................");
			var that = this
			var num = 1
			if (that.page == '/ask' || that.page == '/askDetail') {
				num = 2
			} else if (that.page == '/information' || that.page == '/informationDetail') {
				num = 4
			} else if (that.page == '/datum' || that.page == '/datumDetail') {
				num = 3
			} else {
				num = 1
			}
			let routeUrl = this.$router.resolve({
				path: '/searchpage',
				query: { searchText: that.searchText, num: num }
			});
			window.open(routeUrl.href, '_blank');
			// that.$router.push({
			// 	path: '/searchpage',
			// 	query: {
			// 		searchText: that.searchText,
			// 		num:num
			// 	}
			// });
		},
		// 退出
		signOut() {
			this.islogin = false
			// this.$store.removeItem("setuserInfo")
			localStorage.removeItem("setuserInfo")
			localStorage.removeItem("token");
			this.$router.push({
				path: '/',
				query: {}
			});
		},
		// 去课程列表页
		tocourse(item) {
			// console.log(item)
			this.$router.push({
				path: '/course',
				query: {
					enName: item.enName
					// item: JSON.stringify(item),
				}
			});
		},
		// 分类鼠标移动入
		hoverCategoryList(index) {
			this.hoverindex = index
		},

		// tab 切换
		handleClick(e) {
			// console.log(e)
			this.isType = e.name
			this.activeName = e.name
			if (e.name == '1' && this.checked == true) {
				this.getQrcode()
			}
		},
		// 更变isType
		changeIsType(num) {
			// console.log('12121')
			this.isType = num
		},
		// 切换是否显示密码
		cut(num) {
			if (num == 1) {
				this.isShowPassword = true
			} else {
				this.isShowPassword = false
			}
		},
		// 点击登录
		login(num) {
			if (num == 1) {
				var obj = {
					mobile: this.account,
					password: this.password
				}
				mobile_password(obj).then(res => {
					if (res.data.code == 0) {
						if(res.data.data.error){
							this.$message.error(res.data.data.error);
						}else{
							localStorage.setItem("token", res.data.data.token);
							this.islogin = true;
							this.mine_index()
						}
						
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(error => {
					console.log(error)
				})
			} else if (num == 2) {
				var obj = {
					mobile: this.mobile,
					smsCode: this.verificationCode
				}
				smscode_login(obj).then(res => {
					if (res.data.code == 0) {
						this.islogin = true
						localStorage.setItem("token", res.data.data.token);
						this.mine_index()
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(error => {
					console.log(error)
				})
			}
			else if (num == 3) {
				var obj = {
					mobile: this.mobile,
					smsCode: this.verificationCode,
					openId: this.openId
				}
				bind_mobild(obj).then(res => {
					if (res.data.code == 0) {
						this.islogin = true
						localStorage.setItem("token", res.data.data.token);
						this.mine_index()
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(error => {
					console.log(error)
				})
			} else if (num == 4) {

				var obj = {
					openId: this.openId
				}
				saomazhijiedenglu(obj).then(res => {
					if (res.data.code == 0) {
						this.islogin = true
						localStorage.setItem("token", res.data.data.token);
						this.mine_index()
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(error => {
					console.log(error)
				})
			}
		},
		onTips() {
			this.$message.error('请阅读并同意《用户服务协议》和《隐私政策》');
		},
		// 获取我的信息
		mine_index() {
			mine_index().then(res => {
				if (res.data.code == 0) {
					this.userInfo = res.data.data
					this.$store.commit("setuserInfo", { userInfo: res.data.data })
					localStorage.setItem("setuserInfo", JSON.stringify(res.data.data))
					this.dialogVisible = false
					this.islogin = true
					this.isShowAgreement = false
				} else {
					this.$message.error(res.data.msg);
				}
			}).catch(error => {
				console.log(error)
			})
		},
		getCode4() {
			if (this.mobile == '' || this.mobile == null || this.mobile == undefined) {
				this.$message.error('请输入正确的手机号码');
			} else {
				var obj = {
					mobile: this.mobile,
					type: 4
					// password:this.password
				}
				mobile_send(obj).then(res => {
					if (res.data.code == 0) {
						this.countDownTime()
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(error => {
					console.log(error)
				})
			}
		},
		// 获取验证码
		getCode() {
			if (this.mobile == '' || this.mobile == null || this.mobile == undefined) {
				this.$message.error('请输入正确的手机号码');
			} else {
				var obj = {
					mobile: this.mobile,
					type: 2
					// password:this.password
				}
				mobile_send(obj).then(res => {
					if (res.data.code == 0) {
						this.countDownTime()
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(error => {
					console.log(error)
				})
			}
		},
		// 获取验证码
		wj_getCode() {
			if (this.wj_mobile == '' || this.wj_mobile == null || this.wj_mobile == undefined) {
				this.$message.error('请输入正确的手机号码');
			} else {
				var obj = {
					mobile: this.wj_mobile,
					type: 3
					// password:this.password
				}
				mobile_send(obj).then(res => {
					if (res.data.code == 0) {
						this.countDownTime()
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(error => {
					console.log(error)
				})
			}
		},
		// 倒计时
		countDownTime() {
			let that = this
			let downtime = that.downtime
			setTimeout(() => {
				if (that.downtime > 0) {
					that.downtime = downtime - 1
					that.countdown = that.downtime + '秒后重试'
					that.countDownTime()
				}
				else {
					that.downtime = 60
					that.countdown = '获取验证码'
				}
			}, 1000)
		},
		// 获取二维码
		getQrcode() {
			var that = this
			get_qrcode().then(res => {
				if (res.data.code == 0) {
					that.qrCodeUrl = res.data.data.qrCodeUrl
					that.ticket = res.data.data.ticket
					that.qrcode_status()
				}
			}).catch(error => {
				console.log(error)
			})
		},
		// 获取二维码状态
		qrcode_status() {
			var obj = {
				ticket: this.ticket
			}
			if (this.checked == true && this.isType == '1') { 
				qrcode_status(obj).then(res => {
					if (res.data.code == 0) {
						if (res.data.data.wxOpenId != '' && res.data.data.wxOpenId != null && res.data.data.wxOpenId != undefined) {
							this.openId = res.data.data.wxOpenId
							if (res.data.data.isRegister == 0) {
								this.isShowAgreement = true
							} else {
								// this.mine_index()
								this.login(4)
							}
							// this.mine_index()
							return false
						} else {
							setTimeout(() => {
								this.qrcode_status()
							}, 2000)
						}
					}
				}).catch(error => {
					console.log(error)
				})
			} else if (this.isType == '4') {
				qrcode_status(obj).then(res => {
					if (res.data.code == 0) {
						if (res.data.data.wxOpenId != '' && res.data.data.wxOpenId != null && res.data.data.wxOpenId != undefined) {
							// this.isShowAgreement=true
							this.openId = res.data.data.wxOpenId
							if (res.data.data.isRegister == 0) {
								this.isShowAgreement = true
							} else {
								// this.mine_index()
								this.login(4)
							}
							return false
						} else {
							setTimeout(() => {
								this.qrcode_status()
							}, 2000)
						}
					}
				}).catch(error => {
					console.log(error)
				})
			}
			else {
				// this.$message.warning('请阅读并同意《用户服务协议》和《隐私政策》')
				return false
			}
		}
	}
}
</script>
<style>
.leftNavNewBox{
	display: flex;
	flex-direction: row;
	align-items: center;
}
.xiazaiBox{
	cursor: pointer;
	margin-right: 30px;
    padding: 0 6px;
    height: 34px;
    background: #fdf5ec;
    border-radius: 6px;
    font-family: SourceHanSansCN-Regular,SourceHanSansCN;
    color: #ff5f00;
    
	display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
	
}
.headerNav{
	position: relative;
}

.xuexizhongxinclass {
	cursor: pointer;
	margin-right: 20px;
	/* padding: 0 12px; */
	min-width: 80px;
	height: 34px;
	background: #5f7cfd;
	border-radius: 6px;
	font-size: 14px;
	font-family: SourceHanSansCN-Regular, SourceHanSansCN;
	font-weight: 400;
	color: #ffffff;
	line-height: 21px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
	color: #5f7cfd !important;
	font-weight: 400;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
	background-color: #5f7cfd !important;
	border-color: #5f7cfd;
}

.el-dialog__body {
	padding: 0px 20px;
}
</style>